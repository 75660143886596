.mode{
    margin-top: 2.5rem;
    h4{
        font-size: 17px;
        margin-top: 20px;
    }
    ul{
        margin: 25px 0;
        li{
            display: flex;
            align-items: center;
            padding: 7px 0;
            text-transform: uppercase;
            span{
                height: 30px;
                width: 30px;
                border-radius: 50%;
                margin-right: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 0.2px solid rgba(119, 119, 119, 0.5);
                i{
                    display: none;
                    transition: .2s;
                }
                text-transform: uppercase;
            }
            .active{
                i{display: block;}
            }
        }
    }
}
.color{
    ul{
        margin: 15px 0;
        li{
            display: flex;
            align-items: center;
            padding: 7px 0;
            span{
                height: 25px;
                width: 25px;
                border-radius: 50%;
                margin-right: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                i{
                    display: none;
                }
            }
            .active{
                i{display: block;}
            }
        }
    }
}