.status__card{
    padding: 21px 32px;
    display: flex;
    align-items: center;
    background-color: var(--main-bg);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: color .5s ease 0s;
    justify-content: space-between;
    color: var(--txt-color);
    margin-bottom: 15px;
    &:hover{
        *{color: var(--txt-white) !important;}
        .before{
            transform: scale(3);
            z-index: -2;
        }
    }
    .before{
        transition: .5s;
        background-color: linear-gradient(
            to top right,
            var(--main-color),
            var(--second-color)
        );
        width: 100%;
        height: 100%;
        padding-top: 100%;
        border-radius: 50%;
        transform: scale(1);
        position: absolute;
        right: 50%;
        top: 0;
    }
    .status__card-icon{
        width: 30%;
        height: 100%;
        font-size: 2rem;
        color: var(--txt-color);
        z-index: 1;
    }
    .status__card-info{
        h4{
            font-size: 2rem;
            margin-bottom: 10px;
            z-index: 1;
        }
        span{
        z-index: 1;
        text-transform: capitalize;
        }
    }
}
.dark-background{
    .status__card-icon{
        color: #fff;
    }
}

@media (max-width: 576px) {
    .status__card{
        padding: 14px 5px !important;
        margin-bottom: 10px;
        .status__card-icon{
            font-size: 1.3rem !important;
        }
    }
}