.dropdown{
    position: relative;
    z-index: 99;
    .dropdown__toggle{
        position: relative;
        background-color: transparent;
        border: none;
        outline: none;
        .topnav__user{
            display: flex;
            align-items: center;
            .topnav__user-img{
                width: 35px;
                height: 35px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 10px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .topnav__user-name{
                font-size: 14px;
                font-weight: 600;
            }
        }
        i{
            font-size: 2rem;
            color: var(--txt-color);
        }
        .dropdown__toggle-badge{
            display: flex;
            align-items: center;
            justify-content: center;
            top: -12px;
            right: -10px;
            width: 20px;
            height: 20px;
            font-size: 11px;
            color: var(--txt-white);
            background-color: var(--main-color);
            position: absolute;
            border-radius: 50%;
        }
    }
    .dropdown__content{
        position: absolute;
        top: calc(100% + 5px);
        right: 0;
        background-color: var(main-bg) !important;
        padding: 20px;
        box-shadow: var(--box-shadow);
        border-radius: var(--border-radius);
        transform: scale(0);
        transition: transform 0.3s ease 0s;
        transform-origin: top right;
        .notification__item{
            align-items: center;
            display: flex;
            width: max-content;
            font-size: 15px;
            padding: 14px 5px;
            cursor: pointer;
            &:hover{
                color: var(--main-color);
            }
            i{
                font-size: 17px;
                padding-right: 10px;
            }
        }
    }
    .active{
        transform: scale(1);
        transition: transform 0.5s var(--transition-cubic);
        background-color: #fdfdfd;
    }
    .dropdown__footer{
        width: 100%;
        padding: 20px 0;
        font-size: 17px;
        text-align: center;
    }
}

// Mode settings 

.dark-background{
    .dropdown__toggle{
        color: #fff;
        i{color: #fff;}
    }
    .dropdown__content{
        background: #2d2d2d;
    }
}

// query media

@media (max-width: 768px) {
    .dropdown__toggle{
        i{
            font-size: 1.4rem !important;
        }
    }
    .topnav__user-img{
        width: 25px !important;
        height: 25px !important;
    }
    .dropdown__toggle-badge{
        width: 14px !important;
        height: 14px !important;
        font-size: 7px !important;
        top: -8px !important;
        right: -6.6px !important;
    }
}