.pro__card-wrap{
    padding: 20px;
    .pro__card{
        width: 100%;
        border-radius: var(--border-radius);
        box-shadow: var(--box-shadow);
        background-color: var(--main-bg);
        height: 600px;
        padding: 15px 10px 20px;
        .img__wrap{
            width: 100%;
            height: 320px;
            overflow: hidden;
            transition: transform 0.2s 0s linear;
            border-radius: 15px 15px  0 0;
            &:hover{
                img{
                    transform: scale(1.1);
                }
            }
            img{
                width: 100%;
                height: 320px;
                transform: scale(1);
            }
        }
        .pro__card-info{
            padding: 20px 0;
            a{
                font-size: 15px;
                color: var(--txt-white);
                background-color: var(--main-color);
                padding: 10px 18px;
                border-radius: 7px;
                margin: 12px 0;
            }
            h4{
                text-transform: capitalize;
            }
        }
    }
}