.loader__wrap{
    position: absolute;
    top: 35vh;
    left: 50%;
    transform: translateX(-50%);
}
.loader {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
    border: 4px solid var(--main-color);
    top: 50%;
    animation: loader 2s infinite ease;
  }
  
  .loader-inner {
    display: block;
    width: 100%;
    background-color: var(--main-color);
    animation: loader-inner 2s infinite ease-in;
  }
  
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    
    25% {
      transform: rotate(180deg);
    }
    
    50% {
      transform: rotate(180deg);
    }
    
    75% {
      transform: rotate(360deg);
    }
    
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes loader-inner {
    0% {
      height: 0%;
    }
    
    25% {
      height: 0%;
    }
    
    50% {
      height: 100%;
    }
    
    75% {
      height: 100%;
    }
    
    100% {
      height: 0%;
    }
  }