.table-wrap{
    overflow-x: scroll;
    &::-webkit-scrollbar{
        width: 1px;
        height: 1px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #888;
    }
    table{
        width: 100%;
        thead{
            tr{
                th{
                    font-weight: 600;
                    font-size: 16px;
                    color: #232332;
                    padding: 15px 10px;
                    padding: 11px 0;
                    text-transform: capitalize;
                    padding: 15px 10px;
                }
            }
        }
        tbody{
            tr{
                &:hover{
                    background-color: var(--second-color);
                    td{color: #fff !important;}
                }
                td{
                    padding: 15px 10px;
                    font-size: 14px;
                    color: var(--txt-color);
                    text-transform: capitalize;
                }
            }
        }
    }
    .paginantion{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .paginantion__item{
            cursor: pointer;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border-radius: 50%;
            margin-right: 20px;
        }
        .active{
            background-color: var(--main-color);
            color: var(--txt-white);
        }
    }
}
.dark-background{
    thead{
        tr{th{color: #fff !important;}}
    }
    tbody{
        tr{td{color: #fff !important;}}
    }
}

@media (max-width: 576px) {
    .paginantion__item{
        margin-right: 0 !important;
        width: 25px !important;
        height: 25px !important;
    }
}