@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root{
  --main-bg: #ffffff;
  --second-bg: #fafafb;
  --txt-color: #455560;
  --txt-white: #fff;
  --main-color: #349eff;
  --second-color: #62b4ff;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --border-radius: 15px;

    /* for dark mode */

  --main-bg-light: #ffffff;
  --main-bg-dark: #2d2d2d;
  --second-bg-light: #fafafb;
  --txt-color-light: #455560;
  --box-shadow-light: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);

  --main-color-blue: #349eff;
    --second-color-blue: #62b4ff;

    --main-color-red: #fb0b12;
    --second-color-red: #ff4a6b;

    --main-color-cyan: #10d4d2;
    --second-color-cyan: #2ae9e6;

    --main-color-green: #019707;
    --second-color-green: #4caf50;

    --main-color-orange: #d68102;
    --second-color-orange: #fca11a;

}
.fw{width: 100%;}
.fh{height: 100%;}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

a{
  text-decoration: none;
  color: unset;
}
a:hover{
  color: var(--main-color);
}
button{
  cursor: pointer;
  border: none;
  outline: none;
  color: var(--txt-color);
}
ul{
  list-style-type: none;
}
input{
  border: 2px solid transparent;
  outline: none;
}
input:focus{
  border-color: var(--main-color);
}

.us-card{
  box-shadow: var(--box-shadow);
  padding: 30px;
  border-radius: var(--border-radius);
}
.full-height {
  height: 100%;
}

.us-card.full-height {
  height: calc(100% - 15px);
}
.card__header{
  font-size: 20px;
  padding-bottom: 22px;
}
.card__body{
  text-align: left;
}
.card__footer{
  width: 100%;
  text-align: center;
  padding: 7px 0 3px;
  font-size: 17px;
}
.page-header{
  width: 100%;
  text-align: left;
  text-decoration: underline;
  padding-bottom: 25px;
}

.ofy-scroll{
  overflow-y: scroll;
}

/* ---------- set up colors and mode ----------- */

.light-background {
  background-color: var(--main-bg-light) !important;
}
.light-background *{
  color: #2d2d2d !important;
}
.dark-background {
  background-color: var(--main-bg-dark) !important;
  color: #fff !important;
}

.blue-color, .blue-color::before{
  background-color: var(--main-color-blue) !important;
  color: #fff !important;
}

.red-color, .red-color::before {
  background-color: var(--main-color-red) !important;
  color: #fff !important;
}

.cyan-color, .cyan-color::before {
  background-color: var(--main-color-cyan) !important;
  color: #fff !important;
}

.green-color, .green-color::before {
  background-color: var(--main-color-green) !important;
  color: #fff !important;
}

.orange-color, .orange-color::before {
  background-color: var(--main-color-orange) !important;
  color: #fff !important;
}

.blue-color-hover:hover{
  background-color: var(--main-color-blue) !important;
  color: #fff !important;
}
.red-color-hover:hover{
  background-color: var(--main-color-red) !important;
  color: #fff !important;
}
.cyan-color-hover:hover{
  background-color: var(--main-color-cyan) !important;
  color: #fff !important;
}
.green-color-hover:hover{
  background-color: var(--main-color-green) !important;
  color: #fff !important;
}
.orange-color-hover:hover{
  background-color: var(--main-color-orange) !important;
  color: #fff !important;
}